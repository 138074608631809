import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableBodyRow,
} from "components/Table";
import ModalContainer from "components/modal/ModalContainer";
import CreateCandidate from "./CreateCandidate";
import MenuOpenButton from "components/button/MenuOpenButton";
import CardContainer from "components/cards/CardContainer";
import { jobList } from "dummy-json/jobs";
import React, { useState } from "react";
import HeaderGrid from "components/cards/card-grid/HeaderGrid";
import RightCardHeaderContainer from "components/cards/card-grid/RightCardHeaderContainer";

import FilledButtons from "components/button/FilledButtons";
import ActionMenuBox from "components/menu/action-menu/ActionMenuBox";
import ActionMenuItem from "components/menu/action-menu/ActionMenuItem";
import { useTranslation } from "react-i18next";
import { MdMoreHoriz } from "react-icons/md";

const MenuTrigger = () => {
  const { t } = useTranslation();
  // this function will use when we implement Edit and Delete feature
  const actionHandler = () => {
    console.log("Event");
  };
  return (
    <ActionMenuBox>
      <ActionMenuItem title={t("Edit")} onClickAction={actionHandler} />
      <div className="separator my-0"></div>
      <ActionMenuItem title={t("Delete")} onClickAction={actionHandler} />
    </ActionMenuBox>
  );
};

const Index = () => {
  const [modalToggle, setModalToggle] = useState(false);
  return (
    <React.Fragment>
      <CardContainer>
        <HeaderGrid>
          <div
            className="menu-item px-3"
            data-bs-toggle="modal"
            data-bs-target="#modal_popup"
          >
            <FilledButtons
              onClick={() => setModalToggle(true)}
              label={"Create Candidate"}
              iconName="plus-square"
            />
          </div>
          <RightCardHeaderContainer>
            <MenuOpenButton label="Filter" iconName="setting-4" />
            <MenuOpenButton label="More" iconName="dots-horizontal" />
          </RightCardHeaderContainer>
        </HeaderGrid>
        <Table width="500px">
          <TableHead>
            <TableHeadRow>
              <TableHeadCell>Name</TableHeadCell>
              <TableHeadCell>Current Location</TableHeadCell>
              <TableHeadCell>Current Salary</TableHeadCell>
              <TableHeadCell>Expected Salary</TableHeadCell>
              <TableHeadCell>Current Position</TableHeadCell>
              <TableHeadCell>Applied Position</TableHeadCell>
              <TableHeadCell>Notice Period</TableHeadCell>
              <TableHeadCell>Resume</TableHeadCell>
              <TableHeadCell>Action</TableHeadCell>
            </TableHeadRow>
          </TableHead>

          <TableBody>
            {jobList.map((listItem) => {
              return (
                <TableBodyRow key={listItem.guid}>
                  <TableBodyCell>{listItem.jobRole}</TableBodyCell>
                  <TableBodyCell>{listItem.jobClient}</TableBodyCell>
                  <TableBodyCell>{listItem.jobLocation}</TableBodyCell>
                  <TableBodyCell>{listItem.packageRange}</TableBodyCell>
                  <TableBodyCell>{listItem.isNegotiable}</TableBodyCell>
                  <TableBodyCell>{listItem.count}</TableBodyCell>

                  <TableBodyCell>{listItem.createdDate}</TableBodyCell>
                  <TableBodyCell>
                    <FilledButtons
                      onClick={() => console.log("cancel")}
                      label="Resume"
                    />
                  </TableBodyCell>
                  <TableBodyCell>
                    {" "}
                    <div>
                      <div
                        className="text-primary-white rounded-pill cursor-pointer"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <div className="bg-gray-700 text-white p-1 d-flex justify-content-center mx-1  rounded">
                          <MdMoreHoriz style={{ fontSize: "24px" }} />
                        </div>
                      </div>
                      <MenuTrigger />
                    </div>
                  </TableBodyCell>
                </TableBodyRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContainer>

      <ModalContainer
        modalToggle={modalToggle}
        setModalToggle={setModalToggle}
        title="Upload resume"
      >
        <CreateCandidate />
      </ModalContainer>
    </React.Fragment>
  );
};

export default Index;
