import React, {
  createContext,
  useContext,
  useRef,
  ReactNode,
  useMemo,
  useState,
} from "react";
import { getCurrencies } from "services/commonAPIs";

type WithChildren = {
  children: ReactNode;
};
interface OptionsType {
  value: string;
  label: string;
}
interface CommonContextType {
  commonModalRef: React.RefObject<HTMLDivElement>;
  currencies: OptionsType[];
  fetchCurrenciesHandler: () => void;
}

export const CommonContext = createContext<CommonContextType>({
  commonModalRef: { current: null },
  currencies: [],
  fetchCurrenciesHandler: () => {},
});

export const useCommonContext = () => {
  return useContext(CommonContext);
};

export const CommonProvider: React.FC<WithChildren> = ({ children }) => {
  const commonModalRef = useRef<HTMLDivElement | null>(null);

  const [currencies, setCurrencies] = useState<OptionsType[]>([]);

  const fetchCurrenciesHandler = async () => {
    if (currencies.length <= 0) {
      try {
        await getCurrencies()
          .then((response: any) => {
            setCurrencies(
              response.data.data.map((item: any) => ({
                value: item.guid,
                label: item.code,
              }))
            );
          })
          .catch((error: any) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const value = useMemo(
    () => ({ commonModalRef, currencies, fetchCurrenciesHandler }),
    // eslint-disable-next-line
    [commonModalRef, currencies]
  );

  return (
    <CommonContext.Provider value={value}>{children}</CommonContext.Provider>
  );
};
