import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import Stepper from "components/base/Stepper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputTextField from "components/form/InputTextField";
import { useTranslation } from "react-i18next";
import { useAuthValidationRules } from "helpers/validation-schema/AuthValidationRules";
import { useFormik } from "formik";
import { string, object, array } from "yup";
import { MenuComponent } from "assets/ts/components";
import SelectInput from "components/form/SelectInput";
import FilledButtons from "components/button/FilledButtons";
import FilledGrayButton from "components/button/FilledGrayButton";
import clsx from "clsx";
import jobposter from "assets/images/createJobPoster.svg";
import CreateJobFormTitle from "./components/ui-components/CreateJobFormTitle";
import CloseCrossIcons from "components/base/CloseCrossIcons";
import {
  CreateJobFormContainer,
  CreateJobFormFieldLayout,
} from "./components/ui-components/CreateJobFormLayout";
import { useJobContext } from "hooks‬/JobContext";
import { useCommonContext } from "hooks‬/CommonContext";
import MultiSelectField from "components/form/MultiSelectField";
import { createJob } from "services/jobAPIs";
const inputClass = `form-control py-2 px-3 form-control-md  bg-transparent rounded-5`;

const firstFieldsToValidate = [
  "jobDisplayName",
  "companyBranch",
  "jobShift",
  "jobType",
  "workLocation",
  "selectedJobCategory",
];
const secondFieldsToValidate = [
  "noticePeriod",
  "totalVacancy",
  "campaignStartDate",
  "campaignEndDate",
];
const yesNoOpens = [
  { value: "Y", label: "Yes" },
  { value: "N", label: "No" },
];
const initSelectValue = {
  label: "",
  value: "",
};
interface Props {
  setModalToggle?: Dispatch<SetStateAction<boolean>>;
}

const CreateJobStepperForm: FC<Props> = ({ setModalToggle = () => {} }) => {
  const { t } = useTranslation();
  const {
    jobShift,
    companyBranches,
    jobCategories,
    jobTypes,
    jobWorkModeLocation,
    jobDescriptionSections,
    fetchAllCompanyBranches,
    fetchJobShiftsHandler,
    fetchJobTypesHandler,
    fetchJobCategoriesHandler,
    fetchJobWorkModeLocationHandler,
    fetchAllJobDescriptionSectionsHandler,
  } = useJobContext();
  const { currencies, fetchCurrenciesHandler } = useCommonContext();
  const validationRules = useAuthValidationRules();
  const divRef = useRef<any>(null);
  const [currentStep, setCurrentStep] = useState(1);
  const numSteps = 3;

  const getHtmlString = () => {
    if (divRef.current) {
      const htmlString = divRef.current.innerHTML;
      return htmlString;
    }
    return "";
  };

  const initialValues: any = {
    jobDisplayName: "",
    companyBranch: [],
    jobShift: [],
    jobType: [],
    workLocation: [],
    selectedJobCategory: initSelectValue,
    selectedCurrency: initSelectValue,
    ctc: "",
    isNegotiable: initSelectValue,
    minimumPackage: "",
    maximumPackage: "",
    campaignStartDate: "",
    campaignEndDate: "",
    noticePeriod: "",
    totalVacancy: "",
  };

  useEffect(() => {
    fetchAllCompanyBranches();
    fetchJobShiftsHandler();
    fetchJobTypesHandler();
    fetchJobCategoriesHandler();
    fetchJobWorkModeLocationHandler();
    fetchAllJobDescriptionSectionsHandler();
    fetchCurrenciesHandler();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    jobDescriptionSections.forEach((field: any) => {
      initialValues[field.guid] = "";
    });
    // eslint-disable-next-line
  }, [jobDescriptionSections]);
  const validateSelectSchema = array()
    .min(1, t("messages:MULTI.SELECT.ERROR"))
    .required("Required");

  const createValidationSchema = () => {
    const schema: any = {
      jobDisplayName: validationRules.jobNameValidationSchema,
      companyBranch: validateSelectSchema,
      jobShift: validateSelectSchema,
      jobType: validateSelectSchema,
      workLocation: validateSelectSchema,
      selectedJobCategory: validationRules.jobCategoryValidationSchema,
      noticePeriod: validationRules.noticePeriodValidationSchema,
      totalVacancy: validationRules.totalVacancyValidationSchema,
      campaignStartDate: validationRules.campaignStartDateValidationSchema,
      campaignEndDate: validationRules.campaignEndDateValidationSchema,
    };
    jobDescriptionSections.forEach((field: any) => {
      if (field.isRequired === "Y") {
        schema[field.guid] = string().required(
          `${field.displayName} ${t("messages:IS.REQUIRED")}`
        );
      }
    });

    return object(schema);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: createValidationSchema(),
    onSubmit: async (values, { setSubmitting }) => {
      const jobDescriptionSectionsToSend = jobDescriptionSections
        .map((items: any, index) => {
          if (values[items.guid].length > 0) {
            return {
              jobDescriptionSectionGuid: items.guid,
              jobDescriptionContent: values[items.guid],
              priorityOrder: index,
            };
          } else {
            return null;
          }
        })
        .filter((section) => section !== null);
      const campaignStartDateToSend = values.campaignStartDate.replace(
        "T",
        " "
      );
      const campaignEndDateToSend = values.campaignEndDate.replace("T", " ");

      const jsonToSend = {
        jobDisplayName: values.jobDisplayName,
        jobCategoryGuid: values.selectedJobCategory.value,
        jobShift: values.jobShift,
        jobType: values.jobType,
        workLocation: values.workLocation,
        companyBranch: values.companyBranch,
        ctc: values.ctc,
        isNegotiable: values.isNegotiable.value,
        minimumPackage: values.minimumPackage,
        maximumPackage: values.maximumPackage,
        campaignStartDate: campaignStartDateToSend,
        campaignEndDate: campaignEndDateToSend,
        noticePeriod: values.noticePeriod,
        totalVacancy: values.totalVacancy,
        currencyGuid: values.selectedCurrency.value,
        jobDescription: getHtmlString(),
        jobDescriptionSections: jobDescriptionSectionsToSend,
      };
      console.log(jsonToSend);
      await createJob(jsonToSend)
        .then((response: any) => {
          console.log(response);
          toast.success("Job Created Successfully!", {
            position: "top-right",
          });
        })
        .catch((error: any) => {
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    formik.validateField("selectedJobCategory");
    const indianCurrency = currencies.find((item) => item.label === "INR");
    formik.setFieldValue("selectedCurrency", {
      label: indianCurrency?.label,
      value: indianCurrency?.value,
    });
    // eslint-disable-next-line
  }, [currencies]);
  useEffect(() => {
    MenuComponent.reinitialization();
  }, [currentStep]);

  const firstStepperHandler = async () => {
    let isValid = true;
    for (const field of firstFieldsToValidate) {
      try {
        await formik.validateField(field);
        formik.setFieldTouched(field, true);
        if (
          formik.errors.jobDisplayName ||
          formik.errors.companyBranch ||
          formik.errors.jobShift ||
          formik.errors.jobType ||
          formik.errors.workLocation ||
          formik.errors.selectedJobCategory
        ) {
          isValid = false;
        }
      } catch (error) {
        formik.setFieldTouched(field, true);
        isValid = false;
      }
    }
    if (isValid) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  async function nextStepper() {
    let isValid = true;
    if (currentStep === 1) {
      firstStepperHandler();
    } else if (currentStep === 2) {
      for (const field of secondFieldsToValidate) {
        try {
          await formik.validateField(field);
          formik.setFieldTouched(field, true);
          if (
            formik.errors.noticePeriod ||
            formik.errors.totalVacancy ||
            formik.errors.campaignStartDate ||
            formik.errors.campaignEndDate
          ) {
            isValid = false;
          }
        } catch (error) {
          formik.setFieldTouched(field, true);
          isValid = false;
        }
      }
      if (isValid) {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    }
  }
  const backStep = () => {
    setCurrentStep((prevStep: any) =>
      prevStep >= numSteps ? 1 : prevStep - 1
    );
  };

  const onChangeSelectedField = (target: any) => {
    formik.setFieldValue(target.target.name, {
      value: target.target.value,
      label: target.target.label,
    });
  };

  return (
    <CreateJobFormContainer>
      <CreateJobFormFieldLayout>
        <div className="w-100 h-150px">
          <div className="d-flex w-100 justify-content-end">
            <CloseCrossIcons setModalToggle={setModalToggle} />
          </div>
          <div className="d-flex w-100 flex-column justify-content-center">
            <CreateJobFormTitle />
            <div>
              <Stepper
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              />
            </div>
          </div>
        </div>

        <div className="p-10 w-100 job-stepper-form-field-layout">
          {currentStep === 1 && (
            <>
              <div className="row">
                <div className="col-6">
                  <InputTextField
                    label={t("JOB.NAME")}
                    name="jobDisplayName"
                    labelClass="required"
                    value={formik.values.jobDisplayName}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.jobDisplayName &&
                      formik.errors.jobDisplayName
                    }
                    error={
                      formik.touched.jobDisplayName &&
                      Boolean(formik.errors.jobDisplayName)
                    }
                  />
                </div>
                <div className="col-6">
                  <MultiSelectField
                    label={t("BRANCH.NAME")}
                    labelClass="required"
                    field={{
                      name: "companyBranch",
                      value: formik.values.companyBranch,
                    }}
                    form={formik}
                    options={companyBranches}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <MultiSelectField
                    label={t("JOB.SHIFT")}
                    labelClass="required"
                    field={{
                      name: "jobShift",
                      value: formik.values.jobShift,
                    }}
                    form={formik}
                    options={jobShift}
                  />
                </div>
                <div className="col-6">
                  <MultiSelectField
                    label={t("JOB.TYPE")}
                    labelClass="required"
                    field={{
                      name: "jobType",
                      value: formik.values.jobType,
                    }}
                    form={formik}
                    options={jobTypes}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <MultiSelectField
                    label={t("WORK.MODE")}
                    labelClass="required"
                    field={{
                      name: "workLocation",
                      value: formik.values.workLocation,
                    }}
                    form={formik}
                    options={jobWorkModeLocation}
                  />
                </div>
                <div className="col-6">
                  <SelectInput
                    label={t("JOB.CATEGORY")}
                    labelClass="required"
                    name="selectedJobCategory"
                    selectedOption={formik.values.selectedJobCategory}
                    setSelectedOption={onChangeSelectedField}
                    options={jobCategories}
                    helperText={
                      formik.touched.selectedJobCategory &&
                      formik.errors.selectedJobCategory
                    }
                    error={
                      formik.touched.selectedJobCategory &&
                      Boolean(formik.errors.selectedJobCategory)
                    }
                  />
                </div>
              </div>
            </>
          )}

          {currentStep === 2 && (
            <>
              <div className="row">
                <div className="col-6">
                  <div className="fv-row mb-5 form-group">
                    <label
                      className={"form-label text-dark fs-6 m-2 text-muted "}
                    >
                      {t("CTC.LPA")}
                    </label>

                    <div className="input-group">
                      <input
                        type="number"
                        autoComplete="off"
                        name="ctc"
                        value={formik.values.ctc}
                        onChange={formik.handleChange}
                        className={clsx(` ${inputClass} `, {
                          "is-invalid ": false,
                        })}
                      />
                      <div className="input-group-append">
                        <span
                          style={{
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                          }}
                          className="input-group-text  p-0"
                        >
                          <SelectInput
                            selectedOption={formik.values.selectedCurrency}
                            setSelectedOption={onChangeSelectedField}
                            options={currencies}
                            name="selectedCurrency"
                            isHideBorder={true}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <SelectInput
                    label={t("IS.NEGOTIABLE")}
                    name="isNegotiable"
                    selectedOption={formik.values.isNegotiable}
                    setSelectedOption={onChangeSelectedField}
                    options={yesNoOpens}
                    helperText={
                      formik.touched.isNegotiable && formik.errors.isNegotiable
                    }
                    error={
                      formik.touched.isNegotiable &&
                      Boolean(formik.errors.isNegotiable)
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="fv-row mb-5 form-group">
                    <label
                      className={"form-label text-dark fs-6 m-2 text-muted "}
                    >
                      {t("MINIMUM.PACKAGE")}
                    </label>

                    <div className="input-group">
                      <input
                        type="number"
                        autoComplete="off"
                        name="minimumPackage"
                        value={formik.values.minimumPackage}
                        onChange={formik.handleChange}
                        className={clsx(` ${inputClass} `, {
                          "is-invalid ": false,
                        })}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text p-0 radius-left-0">
                          <SelectInput
                            selectedOption={formik.values.selectedCurrency}
                            setSelectedOption={onChangeSelectedField}
                            options={currencies}
                            name="selectedCurrency"
                            error={false}
                            isHideBorder={true}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="fv-row mb-5 form-group">
                    <label
                      className={"form-label text-dark fs-6 m-2 text-muted "}
                    >
                      {t("MAXIMUM.PACKAGE")}
                    </label>

                    <div className="input-group ">
                      <input
                        type="number"
                        autoComplete="off"
                        name="maximumPackage"
                        value={formik.values.maximumPackage}
                        onChange={formik.handleChange}
                        className={clsx(` ${inputClass} `, {
                          "is-invalid ": false,
                        })}
                      />
                      <div className="input-group-append">
                        <span
                          style={{
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                          }}
                          className="input-group-text p-0"
                        >
                          <SelectInput
                            selectedOption={formik.values.selectedCurrency}
                            setSelectedOption={onChangeSelectedField}
                            options={currencies}
                            name="selectedCurrency"
                            error={false}
                            isHideBorder={true}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <InputTextField
                    label={t("CAMPAIGN.START.DATE")}
                    name="campaignStartDate"
                    labelClass="required"
                    type="datetime-local"
                    value={formik.values.campaignStartDate}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.campaignStartDate &&
                      formik.errors.campaignStartDate
                    }
                    error={
                      formik.touched.campaignStartDate &&
                      Boolean(formik.errors.campaignStartDate)
                    }
                  />
                </div>
                <div className="col-6">
                  <InputTextField
                    label={t("CAMPAIGN.END.DATE")}
                    name="campaignEndDate"
                    labelClass="required"
                    type="datetime-local"
                    value={formik.values.campaignEndDate}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.campaignEndDate &&
                      formik.errors.campaignEndDate
                    }
                    error={
                      formik.touched.campaignEndDate &&
                      Boolean(formik.errors.campaignEndDate)
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <InputTextField
                    label={t("NOTICE.PERIOD")}
                    name="noticePeriod"
                    type="number"
                    labelClass="required"
                    value={formik.values.noticePeriod}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.noticePeriod && formik.errors.noticePeriod
                    }
                    error={
                      formik.touched.noticePeriod &&
                      Boolean(formik.errors.noticePeriod)
                    }
                  />
                </div>
                <div className="col-6">
                  <InputTextField
                    label={t("TOTAL.VACANCY")}
                    labelClass="required"
                    name="totalVacancy"
                    type="number"
                    value={formik.values.totalVacancy}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </>
          )}

          {currentStep === 3 && (
            <>
              {jobDescriptionSections.map((field) => {
                return (
                  <div className="row" key={field.guid}>
                    <div className="col-12">
                      <div className="fv-row mb-5 form-group">
                        <label
                          className={`form-label text-dark fs-6 m-2 text-muted  ${
                            field.isRequired === "Y" && "required"
                          }`}
                        >
                          {field.displayName}
                        </label>
                        <div>
                          <textarea
                            autoComplete="off"
                            name={field.guid}
                            className={clsx(` ${inputClass} `, {
                              "is-invalid ":
                                formik.touched[field.guid] &&
                                Boolean(formik.errors[field.guid]),
                            })}
                            value={formik.values[field.guid]}
                            onChange={formik.handleChange}
                            style={{ height: "100px", resize: "none" }}
                          />
                        </div>
                        {formik.touched[field.guid] &&
                          Boolean(formik.errors[field.guid]) && (
                            <div className="fv-plugins-message-container ">
                              <div className="fv-help-block">
                                <span role="alert">
                                  {field.displayName +
                                    " " +
                                    t("messages:IS.REQUIRED")}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}

          <div className="d-flex justify-content-end pt-4 gap-4">
            <div>
              {currentStep > 1 && (
                <FilledGrayButton onClick={backStep} label={t("BACK")} />
              )}
            </div>
            <div>
              {currentStep === 3 ? (
                <FilledButtons
                  onClick={formik.submitForm}
                  label={t("SUBMIT")}
                />
              ) : (
                <FilledButtons onClick={nextStepper} label={t("NEXT")} />
              )}
            </div>
          </div>
        </div>
      </CreateJobFormFieldLayout>
      <div className="d-flex flex-lg-row-fluid  w-lg-50 bgi-size-cover bg-gray-300 bgi-position-center align-items-center justify-content-center order-1 order-lg-2 ">
        {currentStep === 3 ? (
          <div className="w-100 d-flex flex-column justify-content-center align-items-center">
            <h2 className="text-center mb-5">{t("PREVIEW")}</h2>
            <div className="bg-white  w-75 p-10" style={{ height: "80vh" }}>
              <div
                style={{ overflow: "auto", width: "100%", height: "100%" }}
                ref={divRef}
              >
                {jobDescriptionSections.map((field) => {
                  if (formik.values[field.guid].length > 0) {
                    return (
                      <>
                        <h6>{field.displayName}</h6>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: formik.values[field.guid]?.replace(
                              /\n/g,
                              "<br />"
                            ),
                          }}
                        ></p>
                      </>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          </div>
        ) : (
          <img src={jobposter} className="h-250px" alt="poster" />
        )}
      </div>
      <ToastContainer />
    </CreateJobFormContainer>
  );
};

export default CreateJobStepperForm;
