import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { MdOutlineFileDownload } from "react-icons/md";
import pdfIcon from "assets/svg/pdf-icon.svg";
import excelIcon from "assets/svg/excel-icon.svg";
import { useTranslation } from "react-i18next";
import SelectInput from "components/form/SelectInput";
import FilledGrayButton from "components/button/FilledGrayButton";
import FilledButtons from "components/button/FilledButtons";
import { RxCross1 } from "react-icons/rx";
import { useCommonContext } from "hooks‬/CommonContext";
import { Modal } from "bootstrap";
const options = [
  { value: "option 1", label: "Option 1" },
  { value: "option 2", label: "Option 2" },
  { value: "option 3", label: "Option 3" },
  { value: "option 4", label: "Option 4" },
  { value: "option 5", label: "Option 5" },
];

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const CreateCandidate = () => {
  const { t } = useTranslation();
  const { commonModalRef } = useCommonContext();
  const [docFiles, setDocFiles] = useState<File[]>([]);
  const [excelFiles, setExcelFiles] = useState<File | null>(null);
  const [errorMessages, setErrorMessages] = useState({
    multiFileError: "",
    excelFileError: "",
  });
  const [selectJob, setSelectJob] = useState<any>({
    label: "",
    value: "",
  });

  const onDocDrop = useCallback((acceptedFiles: File[]) => {
    const filteredFiles = acceptedFiles.filter(
      (file) => file.size <= MAX_FILE_SIZE
    );
    const oversizedFiles = acceptedFiles.filter(
      (file) => file.size > MAX_FILE_SIZE
    );
    if (oversizedFiles.length > 0) {
      setErrorMessages((prev) => ({
        ...prev,
        multiFileError: t("messages:MULTI.FILE.ERROR"),
      }));
      setTimeout(() => {
        setErrorMessages((prev) => ({
          ...prev,
          multiFileError: "",
        }));
      }, 5000);
    }
    setDocFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
    // eslint-disable-next-line
  }, []);

  const { getRootProps: getDocRootProps, getInputProps: getDocInputProps } =
    useDropzone({
      onDrop: onDocDrop,
      accept: {
        "application/pdf": [".pdf"],
        "application/msword": [".doc", ".docx"],
      },
      multiple: true,
    });

  const onExcelDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessages((prev) => ({
          ...prev,
          excelFileError: t("messages:EXCEL.FILE.ERROR"),
        }));

        setTimeout(() => {
          setErrorMessages((prev) => ({
            ...prev,
            excelFileError: "",
          }));
        }, 5000);
      } else {
        setExcelFiles(file);
      }
    }
    // eslint-disable-next-line
  }, []);

  const { getRootProps: getExcelRootProps, getInputProps: getExcelInputProps } =
    useDropzone({
      onDrop: onExcelDrop,
      accept: {
        "application/vnd.ms-excel": [".xls", ".xlsx"],
      },
      multiple: false,
    });

  const removeDocFile = (index: number) => {
    setDocFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const removeExcelFile = () => {
    setExcelFiles(null);
  };

  const downloadExcelFile = () => {
    window.location.href =
      "https://fragilestatesindex.org/wp-content/uploads/2021/05/fsi-2021.xlsx";
  };

  const cancelHandler = () => {
    console.log(commonModalRef);
    if (commonModalRef.current) {
      const myModal = Modal.getInstance(commonModalRef.current);
      myModal?.hide();
    }
  };

  return (
    <div className=" overflow-auto p-5">
      <div
        id="doc-upload"
        className="border border-2 p-3 border-dashed radius-12 bg-gray-100 d-flex align-items-center justify-content-between"
        {...getDocRootProps()}
      >
        <input {...getDocInputProps()} />
        <img src={pdfIcon} className="h-45px" alt="pdf-icon" />
        <div className="fs-16">
          {t("messages:DRAG.DROP")}{" "}
          <span className="fw-bold text-decoration-underline cursor-pointer">
            {t("CHOOSE.FILE")}
          </span>
        </div>
      </div>
      {errorMessages.multiFileError.length > 0 && (
        <div className="d-flex justify-content-between text-danger my-0 mx-4">
          <span>{errorMessages.multiFileError}</span>
        </div>
      )}

      <div className="d-flex justify-content-between text-muted my-2">
        <div className="fs-16">{t("SUPPORTED.FORMAT")} PDF, doc, docx</div>
        <div className="fs-16">{t("MAXIMUM.SIZE")}</div>
      </div>

      <div className="d-flex gap-3 flex-wrap">
        {docFiles.length > 0 &&
          docFiles.map((file, index) => (
            <div
              key={file.name}
              className=" d-flex  gap-2 align-items-center border border-1 p-1 px-2 radius-4 bg-gray-200"
            >
              {file.name}
              <RxCross1
                className="cursor-pointer"
                onClick={() => removeDocFile(index)}
                style={{ fontSize: "14px" }}
              />
            </div>
          ))}
      </div>

      <div className="separator separator-content my-10 text-muted fs-16">
        or
      </div>

      <div
        id="excel-upload"
        className="border border-2 p-3 border-dashed radius-12 bg-gray-100 d-flex align-items-center justify-content-between"
        {...getExcelRootProps()}
      >
        <input {...getExcelInputProps()} />
        <img src={excelIcon} className="h-45px" alt="excel-icon" />
        <div className="fs-16">
          {t("messages:DRAG.DROP")}{" "}
          <span className="fw-bold text-decoration-underline cursor-pointer">
            {t("CHOOSE.FILE")}
          </span>
        </div>
      </div>
      {errorMessages.excelFileError.length > 0 && (
        <div className="d-flex justify-content-between text-danger my-0 mx-4">
          <span>{errorMessages.excelFileError}</span>
        </div>
      )}

      <div className="d-flex justify-content-between text-muted my-2">
        <div className="fs-16">
          {t("SUPPORTED.FORMAT")} {t("EXCEL")}
        </div>
        <div className="fs-16">{t("MAXIMUM.SIZE")}</div>
      </div>

      <div>
        {excelFiles && (
          <div className="my-2 d-flex justify-content-between  gap-2 align-items-center border border-1 p-1 px-2 radius-4 bg-gray-200">
            {excelFiles.name}
            <RxCross1
              className="cursor-pointer"
              onClick={removeExcelFile}
              style={{ fontSize: "14px" }}
            />
          </div>
        )}
      </div>

      <button
        style={{ backgroundColor: "#33C48126" }}
        className="radius-12 w-100 border-0 p-3 d-flex align-items-center justify-content-between"
        onClick={downloadExcelFile}
      >
        <div>
          <img src={excelIcon} className="h-35px" alt="excel-icon" />
          <span className="mx-5 text-gray-600">{t("EXCEL.NAME")}</span>
        </div>
        <MdOutlineFileDownload style={{ fontSize: "26px" }} />
      </button>
      <br />

      <SelectInput
        label={t("SELECT.JOB")}
        name="selectedJobCategory"
        selectedOption={selectJob}
        setSelectedOption={(e: any) => {
          setSelectJob(e.target);
        }}
        options={options}
      />

      <div className="d-flex justify-content-end pt-4 gap-4">
        <div>
          <FilledGrayButton
            onClick={() => {
              cancelHandler();
              console.log("cancel");
            }}
            label={t("CANCEL")}
          />
        </div>
        <div>
          <FilledButtons
            onClick={() => console.log("upload")}
            label={t("UPLOAD")}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateCandidate;
