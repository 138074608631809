import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableBodyRow,
} from "components/Table";
import MenuOpenButton from "components/button/MenuOpenButton";
import CardContainer from "components/cards/CardContainer";
import React, { useEffect, useState } from "react";
import HeaderGrid from "components/cards/card-grid/HeaderGrid";
import RightCardHeaderContainer from "components/cards/card-grid/RightCardHeaderContainer";
import CreateJobStepperForm from "./CreateJobStepperForm";
import FilledButtons from "components/button/FilledButtons";
import ActionMenuBox from "components/menu/action-menu/ActionMenuBox";
import ActionMenuItem from "components/menu/action-menu/ActionMenuItem";
import { useTranslation } from "react-i18next";
import { MdMoreHoriz } from "react-icons/md";
import { getAllJobs } from "services/jobAPIs";

const MenuTrigger = () => {
  const { t } = useTranslation();
  return (
    <ActionMenuBox>
      <ActionMenuItem
        title={t("Edit")}
        onClickAction={() => {
          console.log("open");
        }}
      />
      <div className="separator my-0"></div>
      <ActionMenuItem
        title={t("Delete")}
        onClickAction={() => {
          console.log("open");
        }}
      />
    </ActionMenuBox>
  );
};

const Index = () => {
  const [modalToggle, setModalToggle] = useState(false);
  const [jobList, setJobList] = useState<any[]>([]);

  const fetchAllJobs = async () => {
    try {
      await getAllJobs()
        .then((response: any) => {
          setJobList(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchAllJobs();
  }, []);
  return (
    <React.Fragment>
      <CardContainer>
        <HeaderGrid>
          <div>
            <FilledButtons
              onClick={() => setModalToggle(true)}
              label={"Create Jobs"}
              iconName="plus-square"
            />
          </div>
          <RightCardHeaderContainer>
            <MenuOpenButton label="Filter" iconName="setting-4" />
            <MenuOpenButton label="More" iconName="dots-horizontal" />
          </RightCardHeaderContainer>
        </HeaderGrid>
        <Table>
          <TableHead>
            <TableHeadRow>
              <TableHeadCell>Job Name</TableHeadCell>
              <TableHeadCell>Total Vacancy</TableHeadCell>
              <TableHeadCell>CTC</TableHeadCell>
              <TableHeadCell>Minimum Package</TableHeadCell>
              <TableHeadCell>Maximum Package</TableHeadCell>
              <TableHeadCell>Campaign Start Date</TableHeadCell>
              <TableHeadCell>Campaign EndDate</TableHeadCell>
              <TableHeadCell>Is Negotiable</TableHeadCell>
              <TableHeadCell>Action</TableHeadCell>
            </TableHeadRow>
          </TableHead>

          <TableBody>
            {jobList.length > 0 &&
              jobList.map((listItem: any) => {
                return (
                  <TableBodyRow key={listItem.jobDetails.displayName}>
                    <TableBodyCell>
                      {listItem.jobDetails.displayName}
                    </TableBodyCell>
                    <TableBodyCell>
                      {listItem.jobDetails.totalVacancy}
                    </TableBodyCell>
                    <TableBodyCell>{listItem.jobDetails.ctc}</TableBodyCell>
                    <TableBodyCell>
                      {listItem.jobDetails.minimumPackage}
                    </TableBodyCell>
                    <TableBodyCell>
                      {listItem.jobDetails.maximumPackage}
                    </TableBodyCell>
                    <TableBodyCell>
                      {listItem.jobDetails.campaignStartDate}
                    </TableBodyCell>
                    <TableBodyCell>
                      {listItem.jobDetails.campaignEndDate}
                    </TableBodyCell>
                    <TableBodyCell>
                      {listItem.jobDetails.campaignEndDate}
                    </TableBodyCell>
                    <TableBodyCell>
                      {listItem.jobDetails.negotiable}
                    </TableBodyCell>
                    <TableBodyCell>
                      <div>
                        <div
                          className="text-primary-white rounded-pill cursor-pointer"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          <div className="bg-gray-700 text-white p-1 d-flex justify-content-center mx-1  rounded">
                            <MdMoreHoriz style={{ fontSize: "24px" }} />
                          </div>
                        </div>
                        <MenuTrigger />
                      </div>
                    </TableBodyCell>
                  </TableBodyRow>
                );
              })}
          </TableBody>
        </Table>
      </CardContainer>
      {modalToggle && <CreateJobStepperForm setModalToggle={setModalToggle} />}
    </React.Fragment>
  );
};

export default Index;
