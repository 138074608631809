const version = "/v1";
const ats = "/ats";
export const jobApiEndpoint = {
  jobDescription: ats + version + "/job_description/section",
  jobTypes: ats + version + "/job/type",
  jobShift: ats + version + "/job/shift",
  jobCategories: ats + version + "/job/category",
  workModeLocation: ats + version + "/job/work_location",
  companyBranches: ats + version + "/company/branch",
  job: ats + version + "/job",
};

export const commonApiEndpoint = {
  currency: version + "/currency?limit=200",
};
