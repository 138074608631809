export const privateRoutes = {
  dashboard: "/dashboard",
  jobs: "/jobs",
  client: "/client",
  candidates: "/candidates",
  placement: "/placement",
  activities: "/activities",
  inbox: "inbox",
  reports: "/reports",
  settings: "/settings",
  administration: "Administration",
  interviews: "/interviews",
};

export const privateRoutesArray: string[] = (
  Object.keys(privateRoutes) as Array<keyof typeof privateRoutes>
).map((key) => privateRoutes[key]);
