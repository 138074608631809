import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../layout/MasterLayout";
import { Dashboard } from "../pages/dashboard/Dashboard";

import NotFound from "pages/error-pages/NotFound";
import { privateRoutes } from "constant/route/privateRoutes";
import UnderConstruction from "pages/error-pages/UnderConstruction";

import Candidates from "pages/candidates";
import Jobs from "pages/Jobs";
import Clients from "pages/clients";
import ClientProfile from "pages/clients/ClientProfile";
import JobProfile from "pages/Jobs/JobProfile";
import Interviews from "pages/interviews";
const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path={"/"} element={<Navigate to={privateRoutes.dashboard} />} />
        <Route path={privateRoutes.dashboard} element={<Dashboard />} />
        <Route path={privateRoutes.client} element={<Clients />} />
        <Route path={"/client/:clientGuid"} element={<ClientProfile />} />
        <Route path={privateRoutes.jobs} element={<Jobs />} />
        <Route path={privateRoutes.jobs + "/:guid"} element={<JobProfile />} />
        <Route path={privateRoutes.candidates} element={<Candidates />} />
        <Route path={privateRoutes.placement} element={<UnderConstruction />} />
        <Route path={privateRoutes.placement} element={<UnderConstruction />} />
        <Route
          path={privateRoutes.activities}
          element={<UnderConstruction />}
        />
        <Route path={privateRoutes.inbox} element={<UnderConstruction />} />
        <Route path={privateRoutes.reports} element={<UnderConstruction />} />
        <Route
          path={privateRoutes.settings}
          element={<UnderConstruction />}
        />{" "}
        <Route
          path={privateRoutes.administration}
          element={<UnderConstruction />}
        />
        <Route path={privateRoutes.interviews} element={<Interviews />} />
        <Route path={"user-management/users"} element={<UnderConstruction />} />
        <Route path={"user-management/roles"} element={<UnderConstruction />} />
        <Route path={"user-management/users"} element={<UnderConstruction />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export { PrivateRoutes };
