import axios from "axios";
import { getAuth, signOut } from "helpers/AuthHelpers";
const { REACT_APP_URL } = process.env;

const apiInterceptor = axios.create({
  baseURL: REACT_APP_URL ?? "",
});

// Request interceptor for adding the bearer token
apiInterceptor.interceptors.request.use(
  (config: any) => {
    config.headers.Authorization = "" + getAuth()?.authorization;
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for handling 401 status code
apiInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    try {
      if (error.response) {
        if (error.response.status === 401) {
          signOut();
        }
      }
      return Promise.reject(error);
    } catch (error) {
      console.error(error);
    }
  }
);

export default apiInterceptor;
